interface Sample {
  thumbnail?: { url: string };
  noSludgeIcon?: { url: string };
  falseDetectionIcon?: { url: string };
  'Detected Time': string;
  value: string;
}

interface TimeSamples {
  [minute: string]: Sample;
}

interface TimeOfDaySamples {
  [timeOfDay: string]: TimeSamples;
}

interface DateSamples {
  [date: string]: TimeOfDaySamples;
}

export function getDayMonthYear(dateString: string) {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];
  const year = date.getFullYear();
  let days = null;
  if (day == 1) days = day + 'st';
  else if (day == 2) days = day + 'nd';
  else if (day == 3) days = day + 'rd';
  else days = day + 'th';
  return `${days} ${month} ${year}`;
}

export const calculateNextDate = (currentDateStr: string) => {
  const currentDate = new Date(currentDateStr);
  const nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Add 1 day (24 hours) in milliseconds
  return nextDate.toISOString(); // Return next date in YYYY-MM-DD format
};

export const calculatePreviousDate = (currentDateStr: string) => {
  const currentDate = new Date(currentDateStr);
  const previousDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000); // Subtract 1 day (24 hours) in milliseconds
  return previousDate.toISOString(); // Return previous date in YYYY-MM-DD format
};

export function processData(data: any) {
  try {
    if (!data || !data.startTime) {
      console.error('Invalid input data provided to processData.');
      return [];
    }

    const startTime = new Date(data.startTime);
    const metrics = data?.data;

    let currentTime = new Date(startTime);
    let processedData: any[] = [];

    for (let key in metrics) {
      if (metrics.hasOwnProperty(key)) {
        const metric = metrics[key];
        const samplingTime = parseInt(key);
        const dateTime = new Date(currentTime);
        const value = metric.value;
        const thumbnail = metric.thumbnail;
        let issueName = metrics[key].state;

        // Determine the issue name based on sampling time
        if (samplingTime === 30) {
          issueName = 'SV30';
        } else if (samplingTime === 60) {
          issueName = 'SV60';
        } else if (samplingTime === 90) {
          issueName = 'SV90';
        }

        // Append thumbnail to issue name if present
        if (thumbnail) {
          issueName = issueName ? `${issueName} | ${thumbnail}` : thumbnail;
        }

        processedData.push({
          dateTime: dateTime.toISOString(),
          samplingTime: `${samplingTime + 1} minute`,
          value: `${value} %`,
          issueName: issueName,
        });

        // Increment current time by 1 minute
        currentTime.setMinutes(currentTime.getMinutes() + 1);
      }
    }

    return processedData;
  } catch (error) {
    console.error('An error occurred while processing data:', error);
    return [];
  }
}

// Function to convert JSON data to CSV and download it for Sludge Settling Pattern
export function downloadCSV(fileNamePrefix: string, startTime: string, data: any[]) {
  try {
    if (!data?.length) {
      console.error('No data available for download.');
      return;
    }

    // Generate CSV content
    const header = 'Date - Time,Sampling Time,Value,Issue Name';
    const rows = data.map(
      (item: any) => `${item.dateTime},${item.samplingTime},${item.value},"${item.issueName}"`
    );
    const csvContent = `data:text/csv;charset=utf-8,${header}\n${rows.join('\n')}`;

    // Create and trigger the download
    const link = document.createElement('a');
    link.href = encodeURI(csvContent);
    link.download = `${fileNamePrefix}_${startTime}.csv`;

    document.body.appendChild(link); // For Firefox compatibility
    link.click();
    document.body.removeChild(link); // Clean up after the download
  } catch (error) {
    console.error('An error occurred while downloading the CSV:', error);
  }
}

// Function to convert JSON data to CSV and download it for Sludge Value
export function downloadCSVForBarGraph(flocDetectorDefaultSamplesData: any) {
  const replacer = (key: string, value: any) => (value === null ? '' : value);
  const header: string[] = [
    'Date - Time',
    'Shift Name',
    'Sampling Time',
    'Default Sample',
    'Value',
    'Issue Name',
  ];

  const csv: string[] = flocDetectorDefaultSamplesData
    .map((obj: DateSamples) => {
      return Object.entries(obj)
        .map(([date, times]: [string, TimeOfDaySamples]) => {
          return Object.entries(times)
            .map(([timeOfDay, samples]: [string, TimeSamples]) => {
              return Object.entries(samples)
                .map(([minute, sample]: [string, Sample]) => {
                  if (typeof sample === 'object') {
                    const thumbnailUrl = sample.thumbnail?.url || '';
                    const noSludgeUrl = sample.noSludgeIcon?.url || '';
                    const falseDetectionUrl = sample.falseDetectionIcon?.url || '';

                    // Constructing the "Issue Name" value
                    const issueNameValue = `SV${parseFloat(minute)}${
                      thumbnailUrl ? ` | Thumbnail (${thumbnailUrl})` : ''
                    }${noSludgeUrl ? ` | Floating Sludge (${noSludgeUrl})` : ''}${
                      falseDetectionUrl ? ` | False Detection (${falseDetectionUrl})` : ''
                    }`;

                    return {
                      'Date - Time': `${date} ${sample['Detected Time']}`,
                      'Shift Name': timeOfDay,
                      'Sampling Time': minute,
                      'Default Sample': `${samples['Default Sample']} of ${samples['Total Sample']}`,
                      Value: sample.value,
                      'Issue Name': issueNameValue,
                    };
                  }
                  return;
                })
                .filter(row => row !== undefined) as any[];
            })
            .flat();
        })
        .flat();
    })
    .flat()
    .map((row: any) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));

  const dates = flocDetectorDefaultSamplesData.map((entry: DateSamples) => Object.keys(entry)[0]);
  const firstDate = dates.reduce((min: any, date: any) => (date < min ? date : min));
  const lastDate = dates.reduce((max: any, date: any) => (date > max ? date : max));

  csv.unshift(header.join(','));
  const csvArray = csv.join('\r\n');

  const blob = new Blob([csvArray], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', `${firstDate}_${lastDate}_SludgeValueData.csv`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function countWords(sentence: string) {
  // Split the sentence into words
  const words = sentence.split(/\s+/);
  // Count the number of words
  const wordCount = words.length;
  return wordCount;
}

const formatDateTime = (date: Date) => {
  const dateString = date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
  });
  const timeString = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${dateString} ${timeString}`;
};

export const formatTimeRange = (customTimeRangeSettings: any) => {
  if (customTimeRangeSettings?.startDate && customTimeRangeSettings?.endDate) {
    const startTime = new Date(customTimeRangeSettings.startDate);
    const endTime = new Date(customTimeRangeSettings.endDate);
    return `${formatDateTime(startTime)} - ${formatDateTime(endTime)}`;
  }
  return '';
};

export const handleOnClickMinutesIcon = (label: string, setHiddenLabels: any) => {
  setHiddenLabels((prev: any) => {
    const allVisible = !prev['30 minutes'] && !prev['60 minutes'] && !prev['90 minutes'];

    const newHiddenState: { [key: string]: boolean } = {
      ...prev,
      '30 minutes': prev['30 minutes'],
      '60 minutes': prev['60 minutes'],
      '90 minutes': prev['90 minutes'],
    };

    // If all labels are visible, hide the others when one is clicked
    if (allVisible) {
      newHiddenState['30 minutes'] = label !== '30 minutes';
      newHiddenState['60 minutes'] = label !== '60 minutes';
      newHiddenState['90 minutes'] = label !== '90 minutes';
    } else {
      // If the clicked label is already hidden, make all labels visible
      if (!prev[label]) {
        newHiddenState['30 minutes'] = false;
        newHiddenState['60 minutes'] = false;
        newHiddenState['90 minutes'] = false;
      } else {
        // If a different label is clicked, only enable the clicked label
        newHiddenState[label] = !prev[label];
      }
    }
    return newHiddenState;
  });
};

export const handleOnClickIssueIcon = (
  label: string,
  selectedTypeGraph: any,
  setIssueIcon: any,
  setVisibleIcons: any
) => {
  if (selectedTypeGraph === 'Sludge Value') {
    setIssueIcon((prev: any) => {
      const allVisible =
        !prev['falseDetectionIcon'] && !prev['floatingSludgeIcon'] && !prev['noSludgeIcon'];

      const newHiddenState: { [key: string]: boolean } = {
        ...prev,
        falseDetectionIcon: prev['falseDetectionIcon'],
        floatingSludgeIcon: prev['floatingSludgeIcon'],
        noSludgeIcon: prev['noSludgeIcon'],
      };

      // If all labels are visible, hide the others when one is clicked
      if (allVisible) {
        newHiddenState['falseDetectionIcon'] = label !== 'falseDetectionIcon';
        newHiddenState['floatingSludgeIcon'] = label !== 'floatingSludgeIcon';
        newHiddenState['noSludgeIcon'] = label !== 'noSludgeIcon';
      } else {
        // If the clicked label is already hidden, make all labels visible
        if (!prev[label]) {
          newHiddenState['falseDetectionIcon'] = false;
          newHiddenState['floatingSludgeIcon'] = false;
          newHiddenState['noSludgeIcon'] = false;
        } else {
          // If a different label is clicked, only enable the clicked label
          newHiddenState[label] = !prev[label];
        }
      }
      return newHiddenState;
    });
  }
  if (selectedTypeGraph === 'Sludge Settling Pattern') {
    setVisibleIcons((prev: any) => {
      const allVisible = !prev['falseDetection'] && !prev['issueIcon'] && !prev['noSludge'];

      const newHiddenState: { [key: string]: boolean } = {
        ...prev,
        falseDetection: prev['falseDetection'],
        issueIcon: prev['issueIcon'],
        noSludge: prev['noSludge'],
      };
      // If all labels are visible, hide the others when one is clicked
      if (allVisible) {
        newHiddenState['falseDetection'] = label !== 'falseDetection';
        newHiddenState['issueIcon'] = label !== 'issueIcon';
        newHiddenState['noSludge'] = label !== 'noSludge';
      } else {
        // If the clicked label is already hidden, make all labels visible
        if (!prev[label]) {
          newHiddenState['falseDetection'] = false;
          newHiddenState['issueIcon'] = false;
          newHiddenState['noSludge'] = false;
        } else {
          // If a different label is clicked, only enable the clicked label
          newHiddenState[label] = !prev[label];
        }
      }
      return newHiddenState;
    });
  }
};

export function getDataByDate(date: string, flocDetectorDefaultSamplesData: any) {
  const matchingData = flocDetectorDefaultSamplesData.find((item: any) => item.date === date);
  return matchingData ? matchingData.samples : null;
}

export const preloadImages = (imageCache: Record<string, HTMLImageElement>, urls: string[]) => {
  urls.forEach(url => {
    if (!imageCache[url]) {
      const img = new Image();
      img.src = url;
      imageCache[url] = img;
    }
  });
};

export const calculateMaxHeight = (hiddenLabels: any, flocDetectorDefaultSamplesData: any) => {
  let maxHeight = 0;

  flocDetectorDefaultSamplesData.forEach((item: any) => {
    const samples = item.samples ? Object.keys(item.samples) : [];
    samples.forEach(sample => {
      const sampleData = item.samples[sample].data;

      let values30 = 0,
        values60 = 0,
        values90 = 0;
      sampleData.forEach((dataPoint: any) => {
        if (dataPoint.state === '30Mark') {
          values30 = parseInt(dataPoint.value) || 0;
        } else if (dataPoint.state === '60Mark') {
          values60 = parseInt(dataPoint.value) || 0;
        } else if (dataPoint.state === '90Mark') {
          values90 = parseInt(dataPoint.value) || 0;
        }
      });

      if (!hiddenLabels['90Mark'] && !hiddenLabels['60Mark'] && !hiddenLabels['30Mark']) {
        maxHeight = Math.max(maxHeight, values30 + 350);
      } else if (!hiddenLabels['90Mark'] && !hiddenLabels['60Mark']) {
        maxHeight = Math.max(maxHeight, values60 + 350);
      } else if (!hiddenLabels['90Mark'] && !hiddenLabels['30Mark']) {
        maxHeight = Math.max(maxHeight, values30 + 350);
      } else if (!hiddenLabels['60Mark'] && !hiddenLabels['30Mark']) {
        maxHeight = Math.max(maxHeight, values30 + 350);
      } else if (!hiddenLabels['90Mark']) {
        maxHeight = Math.max(maxHeight, values90 + 130);
      } else if (!hiddenLabels['60Mark']) {
        maxHeight = Math.max(maxHeight, values60 + 130);
      } else if (!hiddenLabels['30Mark']) {
        maxHeight = Math.max(maxHeight, values30 + 130);
      }
    });
  });

  return maxHeight;
};

export function extractThumbnails(data: any[]) {
  const thumbnails: string[] = [];
  data.forEach(dayData => {
    Object.values(dayData).forEach((timeData: any) => {
      Object.values(timeData).forEach((sampleData: any) => {
        Object.values(sampleData).forEach((detectedData: any) => {
          if (detectedData.thumbnail?.url) {
            thumbnails.push(detectedData.thumbnail.url);
          }
          if (detectedData.floatingSludgeIcon?.url) {
            thumbnails.push(detectedData.floatingSludgeIcon.url);
          }
          if (detectedData.falseDetectionIcon?.url) {
            thumbnails.push(detectedData.falseDetectionIcon.url);
          }
          if (detectedData.noSludgeIcon?.url) {
            thumbnails.push(detectedData.noSludgeIcon.url);
          }
        });
      });
    });
  });
  return thumbnails;
}

export const calculateBarHeights = (
  values30: number[],
  values60: number[],
  values90: number[],
  hiddenLabels: any
) => {
  const heights30: number[] = [];
  const heights60: number[] = [];
  const heights90: number[] = [];

  for (let i = 0; i < values30.length; i++) {
    // If no labels are hidden, stack all three marks
    if (!hiddenLabels['90 minutes'] && !hiddenLabels['60 minutes'] && !hiddenLabels['30 minutes']) {
      heights90.push(values90[i]);
      heights60.push(values60[i]);
      heights30.push(values30[i]);
    }
    // If only 30 minutes is hidden, stack 60 and 90, but exclude 30
    else if (!hiddenLabels['90 minutes'] && !hiddenLabels['60 minutes']) {
      heights90.push(values90[i]);
      heights60.push(values60[i]);
      heights30.push(0);
    }
    // If only 90 minutes is hidden, stack 60 and 30, but exclude 90
    else if (!hiddenLabels['90 minutes'] && !hiddenLabels['30 minutes']) {
      heights90.push(0);
      heights60.push(values60[i]);
      heights30.push(values30[i]);
    }
    // If only 60 minutes is hidden, stack 90 and 30, but exclude 60
    else if (!hiddenLabels['60 minutes'] && !hiddenLabels['30 minutes']) {
      heights90.push(values90[i]);
      heights60.push(0);
      heights30.push(values30[i]);
    }
    // If only 90 minutes is not hidden, stack 30 and 60, but exclude 90
    else if (!hiddenLabels['90 minutes']) {
      heights90.push(values90[i]);
      heights60.push(0);
      heights30.push(0);
    }
    // If only 60 minutes is not hidden, stack 30 and 90, but exclude 60
    else if (!hiddenLabels['60 minutes']) {
      heights90.push(0);
      heights60.push(values60[i]);
      heights30.push(0);
    }
    // If only 30 minutes is not hidden, stack 60 and 90, but exclude 30
    else if (!hiddenLabels['30 minutes']) {
      heights90.push(0);
      heights60.push(0);
      heights30.push(values30[i]);
    }
    // If all labels are hidden, set all heights to 0
    else {
      heights90.push(0);
      heights60.push(0);
      heights30.push(0);
    }
  }

  return { heights30, heights60, heights90 };
};

export const prepareChartData = (flocDetectorDefaultSamplesData: any, hiddenLabels: any) => {
  const uniqueDates: string[] = [];
  const sampleData: Record<string, any> = {};
  const backgroundColors = {
    '30Mark': '#EBCDAD',
    '60Mark': '#DBA770',
    '90Mark': '#B8742E',
  };

  flocDetectorDefaultSamplesData.forEach((item: any) => {
    const date = item.date;
    const samples = item.samples ? Object.keys(item.samples) : [];

    if (!uniqueDates.includes(date)) {
      uniqueDates.push(date);
    }

    samples.forEach(sampleKey => {
      if (!sampleData[sampleKey]) {
        sampleData[sampleKey] = {
          '30Mark': [],
          '60Mark': [],
          '90Mark': [],
          thumbnails30: [],
          thumbnails60: [],
          thumbnails90: [],
          imageUrls30: [],
          imageUrls60: [],
          imageUrls90: [],
        };
      }

      const sampleValues = item.samples[sampleKey].data;

      sampleValues.forEach((dataPoint: any) => {
        const { value, state, thumbnailUrl, imageUrl } = dataPoint;
        if (state === '30Mark') {
          sampleData[sampleKey]['30Mark'].push(value);
          sampleData[sampleKey]['thumbnails30'].push(thumbnailUrl || '');
          sampleData[sampleKey]['imageUrls30'].push(imageUrl || '');
        } else if (state === '60Mark') {
          sampleData[sampleKey]['60Mark'].push(value);
          sampleData[sampleKey]['thumbnails60'].push(thumbnailUrl || '');
          sampleData[sampleKey]['imageUrls60'].push(imageUrl || '');
        } else if (state === '90Mark') {
          sampleData[sampleKey]['90Mark'].push(value);
          sampleData[sampleKey]['thumbnails90'].push(thumbnailUrl || '');
          sampleData[sampleKey]['imageUrls90'].push(imageUrl || '');
        }
      });
    });
  });

  const datasets = [];
  for (const sampleKey in sampleData) {
    const { heights30, heights60, heights90 } = calculateBarHeights(
      sampleData[sampleKey]['30Mark'],
      sampleData[sampleKey]['60Mark'],
      sampleData[sampleKey]['90Mark'],
      hiddenLabels
    );
    // Pushing datasets for each mark type with same stack key
    datasets.push({
      label: '90Mark',
      data: heights90,
      backgroundColor: backgroundColors['90Mark'],
      stack: sampleKey, // Same stack for overlapping
      hidden: hiddenLabels['90Mark'],
      thumbnails: sampleData[sampleKey]['thumbnails90'],
      imageUrls: sampleData[sampleKey]['imageUrls90'],
      falseDetectionIcon: sampleData[sampleKey]['imageUrls90'],
      floatingSludgeIcon: sampleData[sampleKey]['imageUrls90'],
      noSludgeIcon: sampleData[sampleKey]['imageUrls90'],
      borderRightWidth: 2,
      borderColor: 'transparent',
    });

    datasets.push({
      label: '60Mark',
      data: heights60,
      backgroundColor: backgroundColors['60Mark'],
      stack: sampleKey, // Same stack for overlapping
      hidden: hiddenLabels['60Mark'],
      thumbnails: sampleData[sampleKey]['thumbnails60'],
      imageUrls: sampleData[sampleKey]['imageUrls60'],
      falseDetectionIcon: sampleData[sampleKey]['imageUrls60'],
      floatingSludgeIcon: sampleData[sampleKey]['imageUrls60'],
      noSludgeIcon: sampleData[sampleKey]['imageUrls60'],
      borderRightWidth: 2,
      borderColor: 'transparent',
    });

    datasets.push({
      label: '30Mark',
      data: heights30,
      backgroundColor: backgroundColors['30Mark'],
      stack: sampleKey, // Same stack for overlapping
      hidden: hiddenLabels['30Mark'],
      thumbnails: sampleData[sampleKey]['thumbnails30'],
      imageUrls: sampleData[sampleKey]['imageUrls30'],
      falseDetectionIcon: sampleData[sampleKey]['imageUrls30'],
      floatingSludgeIcon: sampleData[sampleKey]['imageUrls30'],
      noSludgeIcon: sampleData[sampleKey]['imageUrls30'],
      borderRightWidth: 2,
      borderColor: 'transparent',
    });
  }

  return {
    labels: uniqueDates,
    datasets: datasets,
  };
};
