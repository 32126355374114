/* eslint-disable @typescript-eslint/no-unused-vars */
// import React from "react";
import { useEffect, useState } from 'react';
import './Map.css';
import { Grid, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import { Stack, Button } from '@mui/material';
import axiosInstance from '../constants/axiosInstance';
import GeneralisedWidget from '../components/GeneralisedWidgetComponents/GeneralisedWidget';
import TaskViewForDp from '../components/TaskViewForDp/TaskViewForDp';
import CustomSwipableDrawer from '../components/CustomSwipableDrawer/CustomSwipableDrawer';
import { useParams } from 'react-router-dom';
import waterQuantityIcon from '../constants/Images/waterQuantityIcon.svg';
import waterQualityIcon from '../constants/Images/waterQualityIcon.svg';
import safetyIcon from '../constants/Images/safetyIcon.svg';
import biologicalProcessIcon from '../constants/Images/biologicalProcessIcon.svg';
import energyIcon from '../constants/Images/energyIcon.svg';
import equipmentMaintenanceIcon from '../constants/Images/equipmentMaintenanceIcon.svg';
import CircularProgress from '@mui/material/CircularProgress';
import classes from '../styles/dashboardPage.module.css';
import ErrorBoundary from '../components/errorBoundary/errorBoundary';
// import {
//   dashboardPageInterface,
//   pageDropdownInterface,
//   timeFrame,
// } from "../Interfaces/dashboardPageInterfaces";
import AIWidget from '../components/GeneralisedWidgetComponents/AIWidget';
import {
  getDashboardPageData,
  saveShareDashboardData,
  createNewUnifiedDashboard,
  getShareDashboardData,
  getDashboardClustersForClient,
  showMigrationOptionForUnifiedDashboard,
  migrateSinglePlantDashboardPageToUnifiedDashboardForSingleUser,
} from '../services/dashboardPage';
import { toast } from 'react-toastify';
import DashboardToolbar from '../components/Dashboard2/DashboardToolbar';
import CustomDialog from '../globalComponents/dialogs/CustomDialog';
import DateAndTime from '../globalComponents/dateAndTime/DateAndTime';
import BottomBar from '../components/BottomBarComponent/BottomBarComponent';
import EditComponentDialog from '../globalComponents/dialogs/EditComponentDialog';
import { calculateTimeFrame } from '../utilities/helpers';
import { GRANULARITY, TIME_RANGE } from '../constants/constants';
import dpTheme from '../styles/theme/dpTheme';
import { add530Hours } from '../utils/dashboard.utils';
import { updateTimeFrameForRefId } from '../services/commonApis';
import RefreshPageVariables from '../utilities/RefreshPage/RefreshPageVariables';
import RefreshPage from '../utilities/RefreshPage/RefreshPage';
import MapWidget from '../components/MapWidget/MapWidget';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import GeneralisedHeadingWidget from '../components/GeneralisedWidgetComponents/GeneralisedHeadingWidget';
import SankeyWidget from '../components/SankeyWidget/SankeyWidget';
import { useUnifiedDashboardStore } from '../store/UnifiedDashboardStore';
import { getLayoutFromPageData } from '../utils/dashboard.utils';
import CustomGridLayout from '../components/CustomLayouts/CustomGridLayout';
import ManualEntryWidget from '../components/ManualEntryWidget/ManualEntryWidget';
import usePermissionCheck from '../utilities/customHooks/usePermissionCheck';
import { PERMISSION_TAGS } from '../constants/permissions';
import ShareDialog from '../globalComponents/dialogs/ShareDashboardDialog';

export default function UnifiedDashboardPage() {
  const {
    expireTime,
    setExpireTime,
    checkboxChecked,
    setCheckboxChecked,
    customRefreshInterval,
    setCustomRefreshInterval,
    enableCustomRefreshOnly,
    setEnableCustomRefreshOnly,
  } = RefreshPageVariables();

  const plantId = '';

  //Fetching selected pageId from query param
  const { pageId } = useParams<{ pageId: string }>();

  const { selectedUnifiedPageId, setSelectedDndPageId, setSelectedUnifiedPageId } =
    useUnifiedDashboardStore();

  //EditDialogBox is opened when SAVE AS is clicked !, Bottom bar is opened on every action
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isBottomBarOpen, setIsBottomBarOpen] = useState(false);

  //Loading and refreshing
  const [refresh, setRefresh] = useState<boolean>(false);
  const [refreshWidgets, setRefreshWidgets] = useState(false);
  const [loading, setLoading] = useState(true);

  //Show tasks
  const drawerWidth = 340;
  const [tasks, setTasks] = useState(null);
  //@ts-ignore
  const [controller, setController] = useState(new AbortController());

  //Dashboard toolbar
  const [dashboardClusters, setDashboardClusters] = useState<any>(null);

  //Dashboard related data
  const [dashboardPageData, setDashboardPageData] = useState<any>(null);
  const [pageWidgetCluster, setPageWidgetCluster] = useState<any>([]);
  const [showEmbeddedLinks, setShowEmbeddedLinks] = useState(false);
  const [embeddedLinks, setEmbeddedLinks] = useState([]);

  //User Actions
  const [selectedDashboardPage, setSelectedDashboardPage] = useState<any>(null);
  const [changedWidgets, setChangedWidgets] = useState<any>([]);
  const [dashboardSaveData, setDashboardSaveData] = useState<any>(null);
  const [dashboardDateAndTime, setDashboardDateAndTime] = useState({});
  const [newDashboardPage, setNewDashboardPage] = useState<any>({});
  const [selectedGranularity, setSelectedGranularity] = useState(GRANULARITY[1].label);
  const [selectedRelativeTimeRange, setSelectedTimeRange] = useState(TIME_RANGE[0].label);
  //Timeframe will be affected when time is set from global or widget level
  const [timeFrame, setTimeFrame] = useState<any | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [parent, setParent] = useState('dashboard');

  const [openAiSearchBar, setOpenAiSearchBar] = useState(false);
  const [showAiWidget, setShowAiWidget] = useState(false);
  const [sharedForViewOnly, setSharedForViewOnly] = useState(false);

  //@ts-ignore
  const [featureAccess, setFeatureAccess] = useState({
    'AI analysis tool': false,
  });
  const [showMigrationButton, setShowMigrationButton] = useState(false);
  const [loadingForMigrationProcess, setLoadingForMigrationProcess] = useState(false);
  const [gridLayout, setGridLayout] = useState<any[]>([]);
  const [listOfUsers, setListOfUsers] = useState([]);
  const [assignedPermissions, setAssignedPermissions] = useState<
    {
      sharedToUserDetails: { userId: string; email: string };
      permissionType: string;
      permissionId: string; // Include permissionId here
    }[]
  >([]);

  const [availablePermissions, setAvailablePermissions] = useState([]);
  const [openShareDialog, setOpenShareDialog] = useState(false);

  const handleOpenShareDialog = () => {
    setOpenShareDialog(true);
  };

  const handleCloseShareDialog = () => {
    setOpenShareDialog(false);
  };

  const updatePageData = (
    dashboardPageData: any,
    defaultDashboardClusterId: any,
    defaultClusterName: any,
    defaultDashboardPage: any
  ) => {
    setPageWidgetCluster(dashboardPageData?.pageConfig?.data);
    setDashboardPageData(dashboardPageData);
    setShowEmbeddedLinks(dashboardPageData?.pageConfig?.showEmbeddedLinks);
    setEmbeddedLinks(dashboardPageData?.pageConfig?.embeddedLinks);

    let defaultGranularitySettings = dashboardPageData?.pageConfig?.defaultGranularitySettings;
    let defaultTimeRange = dashboardPageData?.pageConfig?.defaultTimeRangeSettings;
    let timeFrame = calculateTimeFrame(defaultTimeRange);
    setDashboardDateAndTime({
      dashboardId: defaultDashboardPage.pageId,
      defaultTimeRange,
    });

    setDashboardSaveData({
      dashboardPageId: defaultDashboardPage.pageId || '',
      selectedGranularity: {
        unit: defaultGranularitySettings.unit,
        unitMultiplier: 1,
      },
      selectedTimeRange: {
        unit: defaultTimeRange.unit,
        unitMultiplier: defaultTimeRange.unitMultiplier,
        timeRangeType: defaultTimeRange.timeRangeType,
        startTime: timeFrame ? timeFrame.startDate : new Date(),
        endTime: timeFrame ? timeFrame.endDate : new Date(),
      },
    });

    setNewDashboardPage({
      dashboardClusterId: defaultDashboardClusterId,
      currentClusterName: defaultClusterName,
      dashboardPageId: defaultDashboardPage.pageId,
      newPageName: defaultDashboardPage.label,
      changes: {
        dashboard: dashboardSaveData,
        widgets: changedWidgets,
      },
    });

    const { startDate: startDate530Ahead, endDate: endDate530Ahead } = add530Hours(
      timeFrame?.startDate,
      timeFrame?.endDate
    );
    updateTimeFrameForRefId(
      startDate530Ahead,
      endDate530Ahead,
      defaultDashboardPage.pageId,
      'dashboardPage'
    ).then(() => {});

    setTimeFrameDetails(dashboardPageData, defaultTimeRange, timeFrame);
  };

  const setTimeFrameDetails = (dashboardPageData: any, defaultTimeRange: any, timeFrame: any) => {
    let granularityValue = GRANULARITY[1].value;
    let granularityLabel = GRANULARITY[1].label;
    GRANULARITY.forEach(granularity => {
      if (
        granularity.value ==
        dashboardPageData?.pageConfig?.defaultGranularitySettings.unit.toLowerCase()
      ) {
        setSelectedGranularity(granularity.label);
        granularityValue = granularity.value;
        granularityLabel = granularity.label;
      }
    });

    let timeRangeLabel = TIME_RANGE[0].label;
    if (defaultTimeRange.timeRangeType == 'relative') {
      TIME_RANGE.forEach((obj: any) => {
        if (obj.value == defaultTimeRange.unitMultiplier && defaultTimeRange.unit == obj.unit) {
          setSelectedTimeRange(obj.label);
          timeRangeLabel = obj.label;
        }
      });
    } else {
      setSelectedTimeRange('Custom');
    }

    setTimeFrame({
      ...timeFrame,
      timeRangeType: defaultTimeRange.timeRangeType,
      granularity: granularityValue,
      granularityLabel,
      timeRangeLabel,
    });
  };
  useEffect(() => {
    return () => {
      setSelectedDndPageId(selectedUnifiedPageId || newDashboardPage.dashboardPageId);
      setSelectedUnifiedPageId(null);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const dashboardClusters = await getDashboardClustersForClient();
      setDashboardClusters(dashboardClusters);

      let defaultDashboardCluster: any = null;
      let defaultDashboardClusterId: string | null = null;
      let defaultClusterName: any = null;
      let defaultDashboardPage: any = null;

      outerLoop: for (let i = 0; i < dashboardClusters.length; i++) {
        const dashboardCluster = dashboardClusters[i];

        if (dashboardCluster?.files && dashboardCluster?.files.length > 0) {
          for (let j = 0; j < dashboardCluster.files.length; j++) {
            const file = dashboardCluster.files[j];

            if (selectedUnifiedPageId) {
              if (selectedUnifiedPageId === file.pageId) {
                defaultDashboardCluster = dashboardCluster;
                defaultDashboardClusterId = defaultDashboardCluster?.folderId;
                defaultClusterName = defaultDashboardCluster?.label;
                defaultDashboardPage = dashboardCluster.files[j];

                break outerLoop; // breaks out of both loops
              }
            } else if (file.default) {
              defaultDashboardCluster = dashboardCluster;
              defaultDashboardClusterId = defaultDashboardCluster?.folderId;
              defaultClusterName = defaultDashboardCluster?.label;
              defaultDashboardPage = dashboardCluster.files[j];
              break outerLoop; // breaks out of both loops
            }
          }
        } else {
          if (selectedUnifiedPageId) {
            if (selectedUnifiedPageId === dashboardCluster.pageId) {
              defaultDashboardCluster = dashboardCluster;
              defaultDashboardClusterId = defaultDashboardCluster?.folderId;
              defaultClusterName = defaultDashboardCluster?.label;
              defaultDashboardPage = dashboardCluster;
              break;
            }
          } else if (dashboardCluster.default) {
            defaultDashboardCluster = dashboardCluster;
            defaultDashboardClusterId = defaultDashboardCluster?.folderId;
            defaultClusterName = defaultDashboardCluster?.label;
            defaultDashboardPage = dashboardCluster;
            break;
          }
        }
      }

      if (!defaultDashboardCluster && !defaultDashboardPage) {
        defaultDashboardCluster = dashboardClusters[0];
        defaultDashboardClusterId = defaultDashboardCluster?.folderId;
        defaultClusterName = defaultDashboardCluster?.label;
        defaultDashboardPage =
          defaultDashboardCluster?.files && defaultDashboardCluster?.files.length > 0
            ? defaultDashboardCluster?.files[0]
            : defaultDashboardCluster;
      }

      setSelectedDashboardPage(defaultDashboardPage);
      setSelectedDndPageId(defaultDashboardPage.pageId);
      setSharedForViewOnly(defaultDashboardPage?.sharedForViewOnly);

      if (defaultDashboardPage?.pageId) {
        const dashboardPageData = await getDashboardPageData(defaultDashboardPage.pageId);
        if (dashboardPageData?.plantType?.includes('ALL_GRID'))
          setGridLayout(getLayoutFromPageData(dashboardPageData?.pageConfig, true));

        setCheckboxChecked(dashboardPageData?.refreshProps?.checkboxChecked);
        setCustomRefreshInterval(dashboardPageData?.refreshProps?.customRefreshInterval);
        setEnableCustomRefreshOnly(
          localStorage.getItem('enableCustomRefreshOnly') === 'true'
            ? true
            : dashboardPageData?.refreshProps?.enableCustomRefreshOnly
        );

        updatePageData(
          dashboardPageData,
          defaultDashboardClusterId,
          defaultClusterName,
          defaultDashboardPage
        );
      } else {
        setDashboardPageData(null);
        setDashboardClusters([]);
        setDashboardSaveData({
          dashboardPageId: '',
          selectedGranularity: {
            unit: 'hours',
            unitMultiplier: 1,
          },
          selectedTimeRange: {
            unit: 'days',
            unitMultiplier: 1,
            timeRangeType: 'relative',
            startTime: new Date(),
            endTime: new Date(),
          },
        });
        setTimeFrame({
          startDate: new Date(),
          endDate: new Date(),
        });
      }
      axiosInstance()
        .get(`/tasks/tasks?index=${0}`)
        .then(({ data }) => {
          setTasks(data.body.tasks);
          setRefresh(!refresh);
        });

      setLoading(false);
      setRefresh(!refresh);
    };

    fetchData();

    showMigrationOptionForUnifiedDashboard().then(data => {
      setShowMigrationButton(data);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, selectedUnifiedPageId]);

  useEffect(() => {
    const fetchShareDashboardData = async (pageId: any, dashboardPageData: any) => {
      try {
        const resp = await getShareDashboardData(dashboardPageData._id);

        const listOfUsers = resp?.listOfUsers;
        const availablePermissions = resp?.availablePermissions;
        const assignedPermissions = resp?.assignedPermissions;

        setListOfUsers(listOfUsers || []);
        setAvailablePermissions(availablePermissions || []);
        setAssignedPermissions(assignedPermissions || []);
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      }
    };

    fetchShareDashboardData(pageId, dashboardPageData);
  }, [openShareDialog, dashboardPageData]);

  const resetPage = () => {
    setDashboardPageData(null);
    setPageWidgetCluster(null);
    setShowEmbeddedLinks(false);
    setEmbeddedLinks([]);
    setChangedWidgets([]);
    setDashboardSaveData(null);
    setDashboardDateAndTime({});
    setNewDashboardPage({});
    setSelectedGranularity(GRANULARITY[1].label);
    setSelectedTimeRange(TIME_RANGE[0].label);
    setTimeFrame(null);
    setIsEditDialogOpen(false);
    setIsBottomBarOpen(false);
  };
  const handleSaveSharedData = async (sharedUsersData: {
    usersPermissions: {
      userId: string;
      permissionType: string;
      action: string;
      permissionId?: string;
    }[];
  }) => {
    // Check if usersPermissions is an array and transform it into the required format
    const usersPermissions = Array.isArray(sharedUsersData.usersPermissions)
      ? sharedUsersData.usersPermissions
      : []; // Fallback to an empty array if it's not

    const dataToSend = {
      pageId: selectedDashboardPage?.pageId,
      usersPermissions,
    };

    try {
      const response = await saveShareDashboardData(dataToSend);
      // Display success toast with response message
      toast.success(response?.message);
    } catch (error) {
      console.log('Failed to save shared data:', error);
    }
  };

  const handlePageMenuChange = async (selectedDashboardPage: any) => {
    setLoading(true);
    resetPage();

    setSelectedUnifiedPageId(selectedDashboardPage.pageId);

    const dashboardPageData = await getDashboardPageData(selectedDashboardPage.pageId);
    if (dashboardPageData?.plantType?.includes('ALL_GRID'))
      setGridLayout(getLayoutFromPageData(dashboardPageData?.pageConfig, true));

    let currentClusterName: any = null;

    if (dashboardClusters && dashboardClusters?.length) {
      outerLoop: for (let i = 0; i < dashboardClusters.length; i++) {
        const dashboardCluster = dashboardClusters[i];

        if (dashboardCluster?.files && dashboardCluster?.files.length > 0) {
          for (let j = 0; j < dashboardCluster.files.length; j++) {
            const file = dashboardCluster.files[j];

            if (selectedDashboardPage.pageId) {
              if (selectedDashboardPage.pageId === file.pageId) {
                currentClusterName = dashboardCluster?.label;
                break outerLoop; // breaks out of both loops
              }
            } else {
              if (file.default) {
                currentClusterName = dashboardCluster?.label;
                break outerLoop; // breaks out of both loops
              }
            }
          }
        } else {
          if (selectedDashboardPage.pageId) {
            if (selectedDashboardPage.pageId === dashboardCluster.pageId) {
              currentClusterName = dashboardCluster?.label;
              break;
            }
          } else {
            if (dashboardCluster.default) {
              currentClusterName = dashboardCluster?.label;
              break;
            }
          }
        }
      }
    }

    updatePageData(
      dashboardPageData,
      selectedDashboardPage.folderId,
      currentClusterName,
      selectedDashboardPage
    );

    setLoading(false);
  };

  const getIconForWidgetCluster = (widgetClusterName: string) => {
    if (widgetClusterName === 'Water Quantity and Availability') return waterQuantityIcon;
    else if (widgetClusterName === 'Inlet and Outlet Water Quality') return waterQualityIcon;
    else if (widgetClusterName === 'Biological Process Health') return biologicalProcessIcon;
    else if (widgetClusterName === 'Safety') return safetyIcon;
    else if (widgetClusterName === 'Energy Consumption') return energyIcon;
    else if (widgetClusterName === 'Equipment Performance and Maintenance')
      return equipmentMaintenanceIcon;
    else return waterQuantityIcon; // should rather be some fall back icon
  };

  const refreshSelectedPageData = () => {
    if (dashboardPageData !== null && selectedDashboardPage !== null) {
      let defaultTimeRange: any = {};

      if (dashboardSaveData?.selectedTimeRange) {
        if (dashboardSaveData.selectedTimeRange.timeRangeType === 'relative') {
          // Calculate the difference in minutes between endTime and startTime
          const timeRangeInMins =
            (dashboardSaveData.selectedTimeRange.endTime -
              dashboardSaveData.selectedTimeRange.startTime) /
            (1000 * 60);

          // Calculate the new startTime and endTime
          const currentDateAndTime = new Date();
          let startTime = new Date(currentDateAndTime.getTime() - timeRangeInMins * 60 * 1000);
          let endTime = currentDateAndTime;

          //Add 530 Hours in time
          startTime = new Date(startTime?.getTime() + 1000 * 60 * 60 * 5.5);
          endTime = new Date(endTime?.getTime() + 1000 * 60 * 60 * 5.5);

          defaultTimeRange = {
            endTime,
            startTime,
            timeRangeInMins,
            timeRangeType: 'relative', // Set to 'relative'
            unit: dashboardSaveData.selectedTimeRange.unit,
            unitMultiplier: dashboardSaveData.selectedTimeRange.unitMultiplier,
          };
        } else {
          defaultTimeRange = {
            endTime: dashboardSaveData.selectedTimeRange.endTime,
            startTime: dashboardSaveData.selectedTimeRange.startTime,
            timeRangeInMins: dashboardSaveData.selectedTimeRange.timeRangeInMins,
            timeRangeType: dashboardSaveData.selectedTimeRange.timeRangeType,
            unit: dashboardSaveData.selectedTimeRange.unit,
            unitMultiplier: dashboardSaveData.selectedTimeRange.unitMultiplier,
          };
        }
      } else {
        defaultTimeRange = dashboardPageData?.pageConfig?.defaultTimeRangeSettings;
      }

      let timeFrame2 = calculateTimeFrame(defaultTimeRange);

      setTimeFrame({
        ...timeFrame,
        startDate: timeFrame2?.startDate,
        endDate: timeFrame2?.endDate,
      });
      setRefresh(!refresh);
      setRefreshWidgets(!refreshWidgets);
      setIsBottomBarOpen(false);

      const { startDate: startDate530Ahead, endDate: endDate530Ahead } = add530Hours(
        timeFrame2?.startDate,
        timeFrame2?.endDate
      );
      updateTimeFrameForRefId(
        startDate530Ahead,
        endDate530Ahead,
        dashboardSaveData.dashboardPageId,
        'dashboardPage'
      ).then(() => {});
    }
  };

  const onSubmitFunc = async (data: any) => {
    setLoading(true);
    const newClusterName = data.input1;
    const newPageName = data.input2 ? data.input2 : `${newDashboardPage.newPageName} Clone`;

    const editedNewData = {
      ...newDashboardPage,
      newClusterName,
      newPageName,
      setPageAsDefault: data.setAsDefault,
      changes: {
        dashboard: dashboardSaveData,
        widgets: changedWidgets,
      },
    };

    // const resp = await createNewDashboard(editedNewData);
    const resp = await createNewUnifiedDashboard(editedNewData);
    if (resp.updateStatus === 'Success') {
      const dashboardClusters = await getDashboardClustersForClient();
      setDashboardClusters(dashboardClusters);
      toast.success(`Created ${newPageName} Page Successfully !`);
    } else {
      toast.error('Error in saving !');
    }
    setLoading(false);
  };

  const openDateAndTimeDialog = () => {
    setOpenDialog(true);
    setParent('dashboard');
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getClassForMapWidgetType = (layoutType: string | null) => {
    if (layoutType == 'mapLayout2') return classes.mapViewLayout1Wrapper;
    if (layoutType == 'mapLayout3') return classes.mapViewLayout2Wrapper;
    else return classes.graphAndTableWidgetWrapper;
  };

  const { hasPermission: canManageDashboard, loading: loadingManageDashboard } = usePermissionCheck(
    PERMISSION_TAGS.UnifiedDashboardList_Manage_AE
  );
  const { hasPermission: canEditPage, loading: loadingEditPage } = usePermissionCheck(
    PERMISSION_TAGS.UnifiedDashboardPage_Configuration_WE
  );
  const { hasPermission: canDownloadAndRefreshReport, loading: loadingDownloadAndRefreshReport } =
    usePermissionCheck(PERMISSION_TAGS.UnifiedDashboardPage_View_R);

  return (
    <ThemeProvider theme={dpTheme}>
      {/* <LayoutComponent style={styles.LayoutStyle} factoryResetContainer={true} plantId={null}> */}
      {checkboxChecked && (
        <RefreshPage
          expireTime={expireTime}
          setExpireTime={setExpireTime}
          checkboxChecked={checkboxChecked}
          setCheckboxChecked={setCheckboxChecked}
          customRefreshInterval={customRefreshInterval}
          setCustomRefreshInterval={setCustomRefreshInterval}
          enableCustomRefreshOnly={enableCustomRefreshOnly}
          setEnableCustomRefreshOnly={setEnableCustomRefreshOnly}
        />
      )}
      {dashboardClusters && dashboardSaveData ? (
        <DashboardToolbar
          heading={`Dashboard`}
          dashboardClusters={dashboardClusters}
          openDateAndTimeDialog={openDateAndTimeDialog}
          refreshSelectedPageData={refreshSelectedPageData}
          plantId={null}
          handlePageMenuChange={handlePageMenuChange}
          setTimeFrame={setTimeFrame}
          selectedGranularity={selectedGranularity}
          setSelectedGranularity={setSelectedGranularity}
          selectedRelativeTimeRange={selectedRelativeTimeRange}
          setSelectedTimeRange={setSelectedTimeRange}
          timeFrame={timeFrame}
          selectedDashboardPage={selectedDashboardPage}
          setSelectedDashboardPage={setSelectedDashboardPage}
          sharedForViewOnly={sharedForViewOnly}
          setIsBottomBarOpen={setIsBottomBarOpen}
          dashboardDateAndTime={dashboardDateAndTime}
          dashboardSaveData={dashboardSaveData}
          setDashboardSaveData={setDashboardSaveData}
          setParent={setParent}
          newDashboardPage={newDashboardPage}
          setNewDashboardPage={setNewDashboardPage}
          setOpenAiSearchBar={setOpenAiSearchBar}
          navigationLinks={{
            dashboardPageList: `/UnifiedDashboardPageList`,
            pageConfig: dashboardPageData?.plantType?.includes('ALL_GRID')
              ? `/DnDUnifiedPageConfig/${selectedDashboardPage?.pageId}`
              : `/UnifiedPageConfig/${selectedDashboardPage?.pageId}`,
          }}
          mode={'unifiedDashboard'}
          pageType={dashboardPageData?.plantType?.[0]}
          canManageDashboard={canManageDashboard}
          canEditPage={canEditPage}
          canDownloadAndRefreshReport={canDownloadAndRefreshReport}
          loadingManageDashboard={loadingManageDashboard}
          loadingEditPage={loadingEditPage}
          loadingDownloadAndRefreshReport={loadingDownloadAndRefreshReport}
          openShareDialog={handleOpenShareDialog}
          canSharePage={true}
        />
      ) : null}
      <CustomDialog open={openDialog} onClose={handleCloseDialog}>
        <DateAndTime
          parent="dashboard"
          setDashboardSaveData={setDashboardSaveData}
          onClose={handleCloseDialog}
          timeFrame={timeFrame}
          setTimeFrame={setTimeFrame}
          setIsBottomBarOpen={setIsBottomBarOpen}
          dashboardDateAndTime={dashboardDateAndTime}
          setSelectedGranularity={setSelectedGranularity}
          setSelectedTimeRange={setSelectedTimeRange}
          setParent={setParent}
        />
      </CustomDialog>
      {!loading && dashboardPageData && pageWidgetCluster ? (
        <Box className={classes.dashboardPage}>
          {dashboardPageData?.plantType?.includes('ALL') ? (
            <Box className={classes.analyticsView}>
              <Box className={classes.analyticsViewBody}>
                {pageWidgetCluster && pageWidgetCluster.length > 0
                  ? pageWidgetCluster.map((widgetCluster: any, clusterIndex: number) => {
                      const { clusterName, customClusterName } = widgetCluster;
                      if (widgetCluster.showWidgetCluster)
                        return (
                          <>
                            <Box className={classes.widgetClusterheader}>
                              <img
                                src={getIconForWidgetCluster(clusterName)}
                                className={classes.widgetClusterImg}
                                alt="widgetClusterImg"
                              ></img>
                              <p>{customClusterName}</p>
                            </Box>
                            <Box className={classes.widgetClusterBody} m={1}>
                              {clusterIndex === 0 && (
                                <Box
                                  className={classes.graphAndTableWidgetWrapper}
                                  sx={{ display: showAiWidget ? 'block' : 'none' }}
                                >
                                  <ErrorBoundary>
                                    <AIWidget
                                      timeFrame={timeFrame}
                                      plantId={plantId as string}
                                      openAiSearchBar={openAiSearchBar}
                                      setOpenAiSearchBar={setOpenAiSearchBar}
                                      setShowAiWidget={setShowAiWidget}
                                    />
                                  </ErrorBoundary>
                                </Box>
                              )}
                              {widgetCluster.widgets.map((widget: any) => {
                                if (
                                  widget.checked &&
                                  ['graph', 'tableC', 'tableD'].includes(widget.widgetType)
                                )
                                  return (
                                    <Box className={classes.graphAndTableWidgetWrapper}>
                                      <ErrorBoundary>
                                        <GeneralisedWidget
                                          widgetId={widget.widgetId}
                                          widgetStructure={widget}
                                          controller={controller}
                                          timeFrame={timeFrame}
                                          refreshDashboard={refresh}
                                          refresh={refreshWidgets}
                                          plantId={null!}
                                          setOpenDialog={setOpenDialog}
                                          parent={parent}
                                          isBottomBarOpen={isBottomBarOpen}
                                          setIsBottomBarOpen={setIsBottomBarOpen}
                                          changedWidgets={changedWidgets}
                                          setChangedWidgets={setChangedWidgets}
                                          setTimeFrame={setTimeFrame}
                                          setParent={setParent}
                                          featureAccess={featureAccess}
                                        />
                                      </ErrorBoundary>
                                    </Box>
                                  );
                                else if (widget.widgetType == 'MapWidget' && widget.checked)
                                  return (
                                    <Box
                                      className={getClassForMapWidgetType(
                                        widget?.widgetLook?.widgetLayout
                                      )}
                                    >
                                      <MapWidget
                                        widgetId={widget.widgetId}
                                        timeFrame={timeFrame}
                                        refresh={refreshWidgets}
                                      />
                                    </Box>
                                  );
                                else if (widget.widgetType == 'SankeyWidget' && widget.checked)
                                  return (
                                    <Box className={classes.graphAndTableWidgetWrapper}>
                                      <SankeyWidget
                                        widgetId={widget.widgetId}
                                        widgetStructure={null}
                                        timeFrame={timeFrame}
                                        refresh={refreshWidgets}
                                        isBottomBarOpen={isBottomBarOpen}
                                        setIsBottomBarOpen={setIsBottomBarOpen}
                                        changedWidgets={changedWidgets}
                                        setChangedWidgets={setChangedWidgets}
                                        setParent={setParent}
                                      />
                                    </Box>
                                  );
                                else return null;
                              })}

                              {widgetCluster?.numberClusters?.map((numberCluster: any) => {
                                return (
                                  <Box
                                    className={
                                      numberCluster.widgets.length > 2
                                        ? classes.numberClusterWrapperForMultipleWidget
                                        : classes.numberClusterWrapperForOneWidget
                                    }
                                  >
                                    {numberCluster.widgets.map((widget: any) => {
                                      return widget?.checked ? (
                                        <Box
                                          className={
                                            numberCluster.widgets.length > 2
                                              ? classes.numberWidgetWrapperType1
                                              : classes.numberWidgetWrapperType2
                                          }
                                        >
                                          <ErrorBoundary>
                                            <GeneralisedWidget
                                              widgetId={widget.widgetId}
                                              widgetStructure={widget}
                                              controller={controller}
                                              timeFrame={timeFrame}
                                              refresh={refreshWidgets}
                                              plantId={null!}
                                              setOpenDialog={setOpenDialog}
                                              parent={parent}
                                              setIsBottomBarOpen={setIsBottomBarOpen}
                                              changedWidgets={changedWidgets}
                                              setChangedWidgets={setChangedWidgets}
                                              setTimeFrame={setTimeFrame}
                                              setParent={setParent}
                                              background={widget?.background}
                                              featureAccess={featureAccess}
                                            />
                                          </ErrorBoundary>
                                        </Box>
                                      ) : null;
                                    })}
                                  </Box>
                                );
                              })}
                            </Box>
                            <Box></Box>
                          </>
                        );
                      else {
                        return null;
                      }
                    })
                  : ''}
                {showEmbeddedLinks === true ? (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    {embeddedLinks?.map((embeddedLink: any) => {
                      return (
                        <Box m={1} p={1} className={classes.embeddedLinksHeader}>
                          <p>{embeddedLink.name}</p>
                          <iframe
                            title={embeddedLink.name}
                            width="100%"
                            height="500"
                            src={embeddedLink.link}
                            style={{ border: 'none' }}
                            allowFullScreen={true}
                          ></iframe>
                        </Box>
                      );
                    })}
                  </Grid>
                ) : null}
              </Box>
            </Box>
          ) : (
            <CustomGridLayout
              layouts={{ lg: gridLayout }}
              style={{ marginTop: '10px', width: 'inherit' }}
              cols={{ lg: 24, md: 16, sm: 8, xs: 8, xxs: 8 }}
            >
              {pageWidgetCluster && pageWidgetCluster.length > 0
                ? pageWidgetCluster.map((widgetCluster: any, clusterIndex: number) =>
                    widgetCluster.widgets.map((widget: any, widgetIndex: number) => {
                      if (widget.widgetType == 'heading')
                        return (
                          <Box key={`${clusterIndex}-${widgetIndex}`}>
                            <GeneralisedHeadingWidget widgetStructure={widget} />
                          </Box>
                        );
                      else if (
                        [
                          'graph',
                          'tableC',
                          'tableD',
                          'number',
                          'number2',
                          'NumberGauge',
                          'flocDetector',
                        ].includes(widget.widgetType)
                      )
                        return (
                          <Box key={`${clusterIndex}-${widgetIndex}`}>
                            <ErrorBoundary>
                              <GeneralisedWidget
                                widgetId={widget.widgetId}
                                widgetStructure={widget}
                                controller={controller}
                                timeFrame={timeFrame}
                                refreshDashboard={refresh}
                                refresh={refreshWidgets}
                                plantId={null!}
                                setOpenDialog={setOpenDialog}
                                parent={parent}
                                isBottomBarOpen={isBottomBarOpen}
                                setIsBottomBarOpen={setIsBottomBarOpen}
                                changedWidgets={changedWidgets}
                                setChangedWidgets={setChangedWidgets}
                                setTimeFrame={setTimeFrame}
                                setParent={setParent}
                                featureAccess={featureAccess}
                                background={widget?.background}
                                enableNewToolBar={true}
                              />
                            </ErrorBoundary>
                          </Box>
                        );
                      else if (widget.widgetType == 'MapWidget')
                        return (
                          <Box key={`${clusterIndex}-${widgetIndex}`}>
                            <MapWidget
                              widgetId={widget.widgetId}
                              timeFrame={timeFrame}
                              refresh={refreshWidgets}
                            />
                          </Box>
                        );
                      else if (widget.widgetType == 'SankeyWidget')
                        return (
                          <Box key={`${clusterIndex}-${widgetIndex}`}>
                            <SankeyWidget
                              widgetId={widget.widgetId}
                              widgetStructure={null}
                              timeFrame={timeFrame}
                              refresh={refreshWidgets}
                              isBottomBarOpen={isBottomBarOpen}
                              setIsBottomBarOpen={setIsBottomBarOpen}
                              changedWidgets={changedWidgets}
                              setChangedWidgets={setChangedWidgets}
                              setParent={setParent}
                            />
                          </Box>
                        );
                      else if (widget.widgetType == 'ManualEntryWidget')
                        return (
                          <Box key={`${clusterIndex}-${widgetIndex}`}>
                            <ManualEntryWidget
                              widgetId={widget.widgetId}
                              widgetStructure={null}
                              refresh={refreshWidgets}
                              enableNewToolBar={true}
                              timeFrame={timeFrame}
                              isBottomBarOpen={isBottomBarOpen}
                              setIsBottomBarOpen={setIsBottomBarOpen}
                              changedWidgets={changedWidgets}
                              setChangedWidgets={setChangedWidgets}
                              setParent={setParent}
                            />
                          </Box>
                        );
                      else return null;
                    })
                  )
                : ''}
            </CustomGridLayout>
          )}

          <CustomSwipableDrawer
            width={drawerWidth}
            buttonName="Upcoming Tasks"
            background="#E6ECF3"
          >
            {tasks != null ? (
              <TaskViewForDp tasks={tasks} />
            ) : (
              <Box className={classes.loadingTaskView}>
                <CircularProgress color="primary" />
              </Box>
            )}
          </CustomSwipableDrawer>
        </Box>
      ) : (
        <Box
          sx={{
            width: '100%',
            height: '300px',
            display: 'grid',
            placeItems: 'center',
            fontSize: 20,
            color: '#777',
          }}
        >
          <Stack alignItems="center">
            <Box>
              You don't have any analytics page configured please click on page icon to get one!
            </Box>
            {showMigrationButton && (
              <Stack alignItems="center" gap={1}>
                <Button
                  disabled={loadingForMigrationProcess}
                  onClick={() => {
                    setLoadingForMigrationProcess(true);
                    migrateSinglePlantDashboardPageToUnifiedDashboardForSingleUser().then(data => {
                      setLoadingForMigrationProcess(false);
                    });
                  }}
                >
                  {!loadingForMigrationProcess
                    ? 'Migrate Default Page From the default Plant Page'
                    : 'Migrating ... '}
                </Button>
                <Box sx={{ fontSize: '13px' }}>After Migration logout and logIn back again</Box>
              </Stack>
            )}
          </Stack>
        </Box>
      )}

      {isBottomBarOpen && !sharedForViewOnly ? (
        <BottomBar
          isBottomBarOpen={isBottomBarOpen}
          setIsBottomBarOpen={setIsBottomBarOpen}
          data={changedWidgets}
          dashboardSaveData={dashboardSaveData}
          parent={parent}
          setIsEditDialogOpen={setIsEditDialogOpen}
        />
      ) : null}
      {isEditDialogOpen && (
        <EditComponentDialog
          isEditDialogOpen={isEditDialogOpen}
          setIsEditDialogOpen={setIsEditDialogOpen}
          heading={'Edit Cluster'}
          title1={'Cluster'}
          title2={'Dashboard'}
          input1={newDashboardPage.currentClusterName || ''}
          input2={''}
          showSetAsDefault={true}
          createdOn={new Date().toISOString()}
          modifiedOn={new Date().toISOString()}
          onSubmitFunc={onSubmitFunc}
        />
      )}

      {openShareDialog && (
        <ShareDialog
          open={openShareDialog}
          onClose={handleCloseShareDialog}
          listOfUsers={listOfUsers}
          availablePermissions={availablePermissions}
          onSave={handleSaveSharedData}
          assignedPermissions={assignedPermissions}
        />
      )}

      {/* </LayoutComponent> */}
    </ThemeProvider>
  );
}
