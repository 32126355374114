import { useEffect } from 'react';
import { Line } from 'react-chartjs-2';

// import { Chart as ChartJS } from 'chart.js';
// import zoomPlugin from 'chartjs-plugin-zoom';
// Register the zoom plugin globally
// ChartJS.register(zoomPlugin);

const LineChartFlocDetector = ({
  imageCache,
  flocData,
  selectedDate,
  selectedSampleIndex,
  handleClickOpen,
}: any) => {
  // Extract data for the selected date and sample
  const selectedSampleData = flocData[selectedDate]?.samples[selectedSampleIndex]?.data || {};
  const graphData: any = Object.values(selectedSampleData);
  const minuteValues = Object.keys(selectedSampleData).map(key => parseInt(key));

  const preloadImages = (urls: string[]) => {
    urls.forEach(url => {
      if (!imageCache[url]) {
        const img = new Image();
        img.src = url;
        imageCache[url] = img;
      }
    });
  };

  const data = {
    labels: minuteValues,
    datasets: [
      {
        label: 'thumbnail',
        data: graphData.map((item: { value: number }) => item.value),
        borderColor: '#CA6B02',
        backgroundColor: 'rgba(202, 107, 2, 0.2)',
        tension: 0.4,
        fill: 'start',
        pointRadius: graphData.map((item: any) => (item.thumbnail ? 3.5 : 0)),
        pointBackgroundColor: graphData.map((item: any) =>
          item.thumbnail ? '#653501' : 'rgba(0,0,0,0)'
        ),
        pointBorderColor: graphData.map((item: any) =>
          item.thumbnail ? '#CA6B02' : 'rgba(0,0,0,0)'
        ),
        pointHoverRadius: graphData.map((item: any) => (item.thumbnail ? 3.5 : 0)),
        pointBorderWidth: 0,
      },
    ],
  };

  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const xValue = context.parsed.x;
            const yValue = context.parsed.y;
            return `${xValue} minutes, ${yValue}`;
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
        },
      },
    },
    scales: {
      x: {
        min: minuteValues[0],
        max: minuteValues[minuteValues.length - 1],
        ticks: {
          stepSize: 30, // This ensures ticks are spaced every 30 minutes
          callback: (value: any) => `${value} minutes`, // Add "minutes" as unit
          maxTicksLimit: 10, // Limit the number of ticks displayed, adjust as needed
        },
        // You may want to add a suggested number of ticks to make the axis more readable
      },
      y: {
        min: Math.min(...graphData.map((item: any) => item.value)),
        max: Math.max(...graphData.map((item: any) => item.value)),
        ticks: {
          stepSize: 10,
        },
      },
    },
  };

  const drawImagesPlugin = {
    id: 'drawImagesPlugin',
    beforeDatasetsDraw: (chart: any) => {
      const ctx = chart.ctx;
      const canvas = chart.canvas;

      const images: any[] = [];

      canvas.onclick = (evt: any) => {
        const res = images.map(({ img, x, y, imageId, thumbnail }) => {
          const width = thumbnail ? 30 : 12;
          const height = 30;
          const { offsetX, offsetY } = evt;
          if (
            offsetX >= x - width / 2 &&
            offsetX <= x + width / 2 &&
            offsetY >= y - height - 10 &&
            offsetY <= y - 10
          ) {
            return imageId;
          }
          return null;
        });
        for (let id of res) {
          if (id !== null) {
            handleClickOpen(id);
          }
        }
      };

      canvas.onmousemove = (evt: any) => {
        let hovering = false;
        images.forEach(({ x, y, thumbnail }) => {
          const width = thumbnail ? 30 : 12;
          const height = 30;
          const { offsetX, offsetY } = evt;
          if (
            offsetX >= x - width / 2 &&
            offsetX <= x + width / 2 &&
            offsetY >= y - height - 10 &&
            offsetY <= y - 10
          ) {
            hovering = true;
          }
        });
        canvas.style.cursor = hovering ? 'pointer' : 'default';
      };

      chart.data.labels.forEach((label: any, dataIndex: any) => {
        const datasets = chart.data.datasets;
        datasets.forEach((dataset: any, datasetIndex: number) => {
          const value = dataset?.data[dataIndex];
          const imgUrl = graphData?.[dataIndex]?.[dataset.label] ?? null;
          const imageId = graphData?.[dataIndex]?.imageId ?? null;
          if (imgUrl && value && !dataset.hidden) {
            const point = chart.getDatasetMeta(datasetIndex).data[dataIndex].getProps(['x', 'y']);
            images.push({
              img: imageCache[imgUrl],
              x: point.x,
              y: point.y,
              imageId,
              thumbnail: dataset.label === 'thumbnail',
            });
          }
        });
      });

      images.forEach(({ img, x, y, thumbnail }) => {
        const width = thumbnail ? 30 : 12;
        const height = 30;
        ctx.drawImage(img, x - width / 2, y - height - 10, width, height);
      });
    },
  };

  useEffect(() => {
    preloadImages(graphData.map((item: any) => item.thumbnail));
  }, [graphData]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'white', // Set the background to white
        border: 'none', // Remove any border
      }}
    >
      <Line data={data} options={options} plugins={[drawImagesPlugin]} />
    </div>
  );
};

export default LineChartFlocDetector;
