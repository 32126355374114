import { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';
import { Box } from '@mui/material';
import { Chart as ChartJS } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { extractThumbnails, getDataByDate, preloadImages, prepareChartData } from '../Helper';

interface Thumbnail {
  url: string;
  imageId: string;
  minuteValue: string;
}

interface Icon {
  url: string;
  imageId: string;
  minuteValue: string;
  floatingSludgeIcon?: boolean;
  falseDetectionIcon?: boolean;
  noSludgeIcon?: boolean;
}

interface ImageDetails {
  img: HTMLImageElement;
  x: number;
  y: number;
  imgWidth: number;
  imgHeight: number;
  imageId: string;
  label: string;
  shift: string;
  minuteValue: string;
  thumbnail: boolean;
}

interface Chart {
  ctx: CanvasRenderingContext2D;
  data: {
    labels: string[];
    datasets: {
      label: string;
      stack: string;
      hidden: boolean;
      thumbnails: Thumbnail[];
      floatingSludgeIcon: Icon[];
      falseDetectionIcon: Icon[];
      noSludgeIcon: Icon[];
    }[];
  };
  canvas: HTMLCanvasElement;
  config: {
    data: {
      datasets: { [key: string]: { stack: string; label: string } };
    };
  };
  getDatasetMeta: (datasetIndex: number) => {
    data: { x: number; y: number }[];
  };
}

interface Props {
  handleClickOpen: any;
  issueIcon: { [key: string]: boolean };
  hiddenLabels: any;
  barGraphData: any;
  expandedVersion: boolean;
}

const StackBarChartFlocDetector: React.FC<Props> = ({
  handleClickOpen,
  issueIcon,
  hiddenLabels,
  barGraphData,
  expandedVersion,
}) => {
  useEffect(() => {
    if (expandedVersion) {
      ChartJS.register(zoomPlugin);
    } else {
      ChartJS.unregister(zoomPlugin);
    }
  }, [expandedVersion]);
  ChartJS.register(zoomPlugin);

  const issueIconRef = useRef(issueIcon); // Create a ref to store the issueIcon prop

  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsMobile({ breakpointValue: 'md' });

  const imageCache: Record<string, HTMLImageElement> = {};

  const chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        bottom: 24.5,
      },
    },
    interaction: {
      intersect: true, // Tooltip only triggers on direct hover
      includeInvisible: true,
    },
    scales: {
      y: {
        beginAtZero: true, // Ensures the y-axis starts from 0
        stacked: false, // Disable stacking on y-axis
        ticks: {
          stepSize: 10, // Step size for tick marks on the y-axis
          callback: function (value: any) {
            return `${value}%`; // Format ticks as percentages
          },
          color: function (value: any) {
            return value === 0 ? '#989DA6' : '#989DA64C'; // Color logic for ticks
          },
        },
        max: 100, // Maximum value on Y-axis is 100% (since it's in percentage)
      },
      x: {
        display: true, // Display the x-axis
        stacked: false, // Disable stacking on x-axis
        ticks: {
          autoSkip: false,
        },
        grid: {
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend if not needed
        position: 'top',
        align: isMobile ? 'start' : 'end',
      },
      tooltip: {
        backgroundColor: '#6D6D6D',
        callbacks: {
          label: function (context: any) {
            const date = context.label;
            const samples = getDataByDate(date, barGraphData);
            if (!samples) return `No data available for this`;

            const sampleName = context.dataset.stack;
            const minuteValue = context.dataset.label;

            if (!samples[sampleName]) return `No data available for ${sampleName}`;

            const sampleData = samples[sampleName].data.find(
              (item: any) => item.state === minuteValue
            );

            if (!sampleData) return `No data available for ${minuteValue}`;

            const detectedTime = sampleData.detectedTime;
            const value = sampleData.value;
            const StartTime = samples[sampleName].startTime;
            const EndTime = samples[sampleName].endTime;

            return [
              `Detected Time: ${detectedTime}`,
              `StartTime: ${StartTime}`,
              `EndTime: ${EndTime}`,
              `Value: ${value}%`, // Display percentage value
              `Sample: ${sampleName}`,
            ];
          },
        },
      },
    },
    barPercentage: isMobile ? 0.85 : isTablet ? 0.8 : 0.8, // Adjust for mobile/tablet
    categoryPercentage: isMobile ? 0.65 : isTablet ? 0.4 : 0.4, // Adjust for mobile/tablet
  };

  const drawThumbnailsPlugin: any = {
    id: 'drawThumbnails',
    afterDatasetsDraw: (chart: Chart) => {
      const ctx = chart.ctx;
      const datasets = chart.data.datasets;
      const canvas = chart.canvas;
      const images: ImageDetails[] = [];

      // Create custom tooltip element
      let tooltipEl = document.getElementById('chartjs-tooltip') as HTMLDivElement;
      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.id = 'chartjs-tooltip';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.backgroundColor = '#6D6D6D';
        tooltipEl.style.color = '#fff';
        tooltipEl.style.borderRadius = '4px';
        tooltipEl.style.padding = '12px';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.opacity = '0';
        tooltipEl.style.fontSize = '12px';
        document.body.appendChild(tooltipEl);
      }

      // Hide the tooltip when not hovering over an image
      canvas.addEventListener('mouseout', () => {
        tooltipEl.style.opacity = '0';
      });

      canvas.onclick = (evt: MouseEvent) => {
        const mouseX = evt.offsetX;
        const mouseY = evt.offsetY;

        const clickedImageIds = images
          .filter(
            ({ x, y, imgWidth, imgHeight }) =>
              mouseX >= x && mouseX <= x + imgWidth && mouseY >= y && mouseY <= y + imgHeight
          )
          .map(({ imageId }) => imageId);

        if (clickedImageIds.length > 0) {
          handleClickOpen(clickedImageIds[0]);
        }
      };

      canvas.onmousemove = (evt: any) => {
        const mouseX = evt.offsetX;
        const mouseY = evt.offsetY;
        let hovering = false;
        images.forEach(({ x, y, imgWidth, imgHeight }) => {
          if (mouseX >= x && mouseX <= x + imgWidth && mouseY >= y && mouseY <= y + imgHeight) {
            hovering = true;
          }
        });
        canvas.style.cursor = hovering ? 'pointer' : 'default';
      };

      chart.data.labels.forEach((label, index) => {
        const shiftWiseThumbnails: { [key: string]: Thumbnail[] } = {};
        const shiftWiseIcons: { [key: string]: Icon[] } = {};
        datasets.forEach(dataset => {
          const minuteValue = dataset.label;
          const shift = dataset.stack;
          if (!shiftWiseThumbnails[shift]) {
            shiftWiseThumbnails[shift] = [];
          }
          if (!shiftWiseIcons[shift]) {
            shiftWiseIcons[shift] = [];
          }

          if (!dataset.hidden && dataset.thumbnails[index]) {
            shiftWiseThumbnails[shift].push({
              ...dataset.thumbnails[index],
              minuteValue,
            });
          }

          if (
            !issueIconRef.current.floatingSludgeIcon &&
            !dataset?.hidden &&
            dataset?.floatingSludgeIcon[index]
          ) {
            shiftWiseIcons[shift].push({
              ...dataset?.floatingSludgeIcon[index],
              minuteValue,
              floatingSludgeIcon: true,
            });
          }
          if (
            !issueIconRef.current.falseDetectionIcon &&
            !dataset?.hidden &&
            dataset?.falseDetectionIcon[index]
          ) {
            shiftWiseIcons[shift].push({
              ...dataset?.falseDetectionIcon[index],
              minuteValue,
              falseDetectionIcon: true,
            });
          }
          if (
            !issueIconRef.current.noSludgeIcon &&
            !dataset?.hidden &&
            dataset?.noSludgeIcon[index]
          ) {
            shiftWiseIcons[shift].push({
              ...dataset?.noSludgeIcon[index],
              minuteValue,
              noSludgeIcon: true,
            });
          }
        });

        Object.keys(shiftWiseThumbnails).forEach(shift => {
          const barIndex = Object.keys(chart.config.data.datasets).findIndex(
            key =>
              chart.config.data.datasets[key].stack === shift &&
              chart.config.data.datasets[key].label === '30 minutes'
          );

          // Add this check to ensure barIndex is valid
          if (barIndex !== -1) {
            const bar = chart.getDatasetMeta(barIndex).data[index];
            const barX = bar?.x; // Optional chaining to prevent accessing undefined properties
            const barTopY = bar?.y;

            if (barX !== undefined && barTopY !== undefined) {
              const thumbnails = shiftWiseThumbnails[shift].reverse();
              const icons = shiftWiseIcons[shift].reverse();
              let cumulativeHeight = 0;
              const marginBottom = 5;

              thumbnails.forEach((thumbnailSrc, thumbnailIndex) => {
                const img = imageCache[thumbnailSrc.url];
                const imgWidth = isMobile || isTablet ? 16 : 18;
                const imgHeight = isMobile || isTablet ? 16 : 18;
                const gap = 3;
                const x = barX - imgWidth / 2;
                const y = barTopY - imgHeight - cumulativeHeight - gap * (thumbnailIndex + 1);

                if (img.complete) {
                  ctx.drawImage(img, x, y, imgWidth, imgHeight);
                  cumulativeHeight += imgHeight + gap;
                } else {
                  img.onload = () => {
                    ctx.drawImage(img, x, y, imgWidth, imgHeight);
                    cumulativeHeight += imgHeight + gap;
                  };
                }

                images.push({
                  img,
                  x,
                  y,
                  imgWidth,
                  imgHeight,
                  imageId: thumbnailSrc.imageId,
                  label: label,
                  shift: shift,
                  minuteValue: thumbnailSrc.minuteValue,
                  thumbnail: true,
                });
              });

              icons.forEach((iconSrc, iconIndex) => {
                const img = imageCache[iconSrc.url];
                let imgWidth = 16;
                let imgHeight = 16;

                if (iconSrc.floatingSludgeIcon) {
                  imgWidth = 8;
                  imgHeight = 17;
                }

                const gap = 2;
                const x = barX - imgWidth / 2;
                const y =
                  barTopY - imgHeight - cumulativeHeight - gap * (iconIndex + 1) - marginBottom;

                if (img.complete) {
                  ctx.drawImage(img, x, y, imgWidth, imgHeight);
                  cumulativeHeight += imgHeight + gap;
                } else {
                  img.onload = () => {
                    ctx.drawImage(img, x, y, imgWidth, imgHeight);
                    cumulativeHeight += imgHeight + gap;
                  };
                }

                images.push({
                  img,
                  x,
                  y,
                  imgWidth,
                  imgHeight,
                  imageId: iconSrc.imageId,
                  label: label,
                  shift: shift,
                  minuteValue: iconSrc.minuteValue,
                  thumbnail: false,
                });
              });
            }
          }
        });
      });

      canvas.addEventListener('mousemove', (evt: MouseEvent) => {
        const mouseX = evt.offsetX;
        const mouseY = evt.offsetY;

        const hoveredImages = images.filter(
          ({ x, y, imgWidth, imgHeight }) =>
            mouseX >= x && mouseX <= x + imgWidth && mouseY >= y && mouseY <= y + imgHeight
        );

        if (hoveredImages.length > 0) {
          const hoveredImage = hoveredImages[0];
          const getAllShiftDetails = getDataByDate(hoveredImage.label, barGraphData);
          const detectedTime =
            getAllShiftDetails[hoveredImage.shift][hoveredImage.minuteValue]['Detected Time'];
          tooltipEl.style.opacity = '1';
          tooltipEl.style.left = `${evt.pageX}px`;
          tooltipEl.style.top = `${evt.pageY}px`; // Adjust as needed
          if (hoveredImage.thumbnail) {
            tooltipEl.innerHTML = `
            <div>
              <div style="font-weight: 700;">Sludge Color</div>
              <div> <span style="font-weight: 700;">Detected Time:</span> ${detectedTime}</div>
              <div> <span style="font-weight: 700;">Date: </span>${hoveredImage.label}</div>
            </div>
          `;
          } else {
            tooltipEl.innerHTML = `
            <div>
              <div> <span style="font-weight: 700;">Issue Name: </span> Aliquet sit id quis pulvinar</div>
              <div> <span style="font-weight: 700;">Date: </span>${hoveredImage.label}</div>
            </div>
          `;
          }
        } else {
          tooltipEl.style.opacity = '0';
        }
      });
    },
  };

  const [chartData, setChartData] = useState(prepareChartData(barGraphData, hiddenLabels));

  useEffect(() => {
    setChartData(prepareChartData(barGraphData, hiddenLabels));
  }, [hiddenLabels]);

  useEffect(() => {
    preloadImages(imageCache, extractThumbnails(barGraphData));
  }, [imageCache]);

  useEffect(() => {
    issueIconRef.current = issueIcon;
  }, [issueIcon]);

  return (
    <Box sx={{ display: 'flex', overflowX: 'hidden', height: '100%' }} className={'boxNum1'}>
      <Box
        sx={{ maxWidth: '100vw', overflowX: 'scroll', scrollbarWidth: 'none', height: 'inherit' }}
        className={'boxNum2'}
      >
        <Box
          sx={{ width: isMobile ? '200vw' : isTablet ? '150vw' : '100vw', height: 'inherit' }}
          className={'boxNum3'}
        >
          <Bar
            data={chartData}
            options={chartOptions} // <-- Use the updated `chartOptions`
            plugins={[drawThumbnailsPlugin]}
            height={expandedVersion ? '400px' : '90%'}
            id="myChart"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default StackBarChartFlocDetector;
